/* Basics setup*/
@import url('https://fonts.googleapis.com/css2?family=Akshar:wght@300;400;500;600;700&family=Roboto+Flex:opsz,wght@8..144,100;8..144,200;8..144,400;8..144,500;8..144,600;8..144,700&display=swap');
*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
html {
  scroll-behavior: smooth;
  font-size: 62.5%; /* % from default value 16px now the default value is 10px an I can easy work with rem*/
}
:root {
  --mainTransition: all 0.3s linear;
  --navTransaction: top 0.4s ease-out;
  --mainSpacing: 0.3rem;
  --lightShadow: 0.4rem 0.5rem 1rem 0 black;
  --lightShadowTop: -0.1rem -0.1rem 0.5rem 0 rgba(0, 0, 0, 0.493);
  --darkShadow: 0.4rem 1rem 1rem 0 black;
 
  /* ///////////////////////// */
  --mainAccent: #63AAFF;
  --color-grey-light-1: #cbcbcb;
  --mainText: #333232;



  --light-blue: #7d88a8;
  --light-blue-2: #51618e;
  --medium-blue: #2f4073;
  --dark-blue: #06143d;
  --dark-blue-2: #152658;
 
  --main-black: #000;
  --main-light: #fff;
}
body {
  color: var(--mainText);
  background-color: var(--mainDark);
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  line-height: 1.4;
  z-index: -1;
  margin: 0 auto;
  max-width: 180rem;
    letter-spacing: 2px;
}

.position-fix{
  margin-bottom: -2rem;
}
